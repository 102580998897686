<template>
  <div class="app-password-success text-start mt-3 text-center">
    <span class="app-password-success__icon material-icons text-success">
      task_alt
    </span>

    <p class="mt-4 text-sm">
      Het nieuwe wachtwoord is opgeslagen.<br />
      Je kunt nu in de app inloggen met het nieuwe wachtwoord.
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validateOTP } from '@/api/providers/account';

export default {
  name: "LoginPassword",

  data: () => ({
    error: '',
    loading: false,
    password: '',
  }),

  methods: {
    ...mapActions('account', [
      'setAuth',
      'setOrganizations',
    ]),

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      validateOTP(this.$route.params.username, this.password)
        .then(({ auth, organizations }) => {
          this.setAuth(auth);
          this.setOrganizations(organizations);

          this.$router.push({
            name: 'account-organisations',
          });
        })
        .catch(({ message }) => {
          this.error = message;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .app-password-success {

    &__icon {
      font-size: 90px;
    }
  }
</style>
